<template>
  <div class="pay-item-root-container">
    <div
      class="pay-item-cell"
      style="width: 40%; flex-direction: row; justify-content: flex-start"
    >
      <my-avatar
        :size="50"
        :name="dataSource.client_name"
        :img_url="dataSource.img_url"
      />
      <div class="pay-item-user-conatiner">
        <span style="font-size: 15px; font-weight: bold; margin-bottom: 10px"
          >{{ dataSource.client_name }}
          <span
            v-if="dataSource.is_dgj"
            style="
              font-size: 12px;
              background-color: #5cadff;
              color: #fff;
              padding: 2px 5px;
              border-radius: 3px;
              margin-left: 5px;
            "
            >董高监</span
          >
        </span>
        <div class="pay-item-row">
          <Icon type="ios-phone-portrait" />
          <span style="font-size: 12px; margin-left: 5px">{{
            dataSource.client_phone
          }}</span>
          <Divider type="vertical" />
          <Icon type="ios-card-outline" />
          <span style="font-size: 12px; margin-left: 5px">{{
            dataSource.client_idnum
          }}</span>
          <Divider type="vertical" />
          <Icon type="ios-pin-outline" />
          <span style="font-size: 12px; margin-left: 5px">{{
            dataSource.position
          }}</span>
        </div>
      </div>
    </div>
    <div class="pay-item-cell" style="width: 25%">
      <div class="pay-item-row">
        <my-avatar
          :size="20"
          :name="dataSource.bank_name"
          :img_url="dataSource.bank_img_url"
        />
        <span style="font-size: 15px; font-weight: bold; margin-left: 10px">{{
          dataSource.bank_name
        }}</span>
      </div>
      <span style="font-size: 12px; color: #5f5f5f; margin-top: 10px">{{
        dataSource.bank_num
      }}</span>
    </div>
    <div class="pay-item-cell" style="width: 25%">
      <fee :amount="dataSource.amount" :primarySize="15" :secondarySize="15" />
    </div>
    <div class="pay-item-cell" style="width: 25%">
      <span style="font-size: 15px; font-weight: bold; margin-left: 10px">{{
        dataSource.post
      }}</span>
    </div>
    <div class="pay-item-cell" style="width: 10%">
      <Icon
        :size="30"
        color="#19be6b"
        type="md-checkmark-circle"
        v-if="dataSource.uc_state == 1"
      />
      <Poptip
        trigger="hover"
        title="错误信息"
        transfer
        :content="dataSource.err_msg"
        v-else
      >
        <Icon :size="30" color="#ed4014" type="md-close-circle" />
      </Poptip>
    </div>
  </div>
</template>

<script>
import Fee from "..//Fee";
import MyAvatar from "../MyAvatar";
export default {
  props: {
    dataSource: Object,
  },
  components: {
    fee: Fee,
    "my-avatar": MyAvatar,
  },
};
</script>

<style scoped>
.pay-item-root-container {
  min-height: 90px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 10px;
  margin: 0 5px;
  transition: all 0.2s ease;
  cursor: pointer;
  box-shadow: 0px 0px 1px #dddddd;
}
.pay-item-root-container:hover {
  box-shadow: 0px 0px 10px #dddddd;
  transform: translateY(-2px);
}
.pay-item-cell {
  width: 20%;
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.pay-item-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.pay-item-user-conatiner {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}
</style>