<template>
  <Modal v-model="isShowModal" footer-hide fullscreen>
    <div class="pay-list-modal-root-container">
      <div class="pay-list-modal-header-container">
        <div class="pay-list-modal-title-container">
          <span style="font-size: 32px; font-weight: bold">{{ name }}</span>
          <span style="font-size: 14px; color: #4f4e4e; margin-top: 10px">
            {{ sub_name }}
          </span>
        </div>
        <div
          class="pay-list-modal-title-container"
          style="align-items: flex-end"
        >
          <fee :amount="totalAmount" :primarySize="32" :secondarySize="28" />
          <span style="font-size: 13px; font-weight: bold; margin-top: 10px"
            >{{ dataSource.length }}笔，合计金额</span
          >
        </div>
      </div>

      <pay-list :data-source="dataSource" />
    </div>
  </Modal>
</template>

<script>
import Fee from "../Fee";
import PayList from "../List/PayList";
export default {
  components: {
    fee: Fee,
    "pay-list": PayList,
  },
  props: {
    dataSource: Array,
  },
  data() {
    return {
      isShowModal: false,

      name: "",
      sub_name: "",
    };
  },
  methods: {
    open(name, sub_name) {
      this.name = name;
      this.sub_name = sub_name;

      this.isShowModal = true;
    },
    close() {
      this.isShowModal = false;
    },
  },
  computed: {
    totalAmount: function () {
      let total_amount = 0;
      for (let item of this.dataSource) {
        total_amount += item.amount;
      }
      return total_amount;
    },
  },
};
</script>

<style scoped>
.pay-list-modal-root-container {
  display: flex;
  flex-direction: column;
  padding-top: 100px;
  padding-left: 285px;
  padding-right: 150px;
  overflow: hidden;
}
.pay-list-modal-header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 100px;
}
.pay-list-modal-title-container {
  display: flex;
  flex-direction: column;
}
</style>