<template>
  <div class="pay-list-root-container">
    <div class="pay-list-header-container">
      <span style="width: 40%; text-align: center; font-weight: bold"
        >姓名</span
      >
      <span style="width: 25%; text-align: center; font-weight: bold"
        >银行卡</span
      >
      <span style="width: 25%; text-align: center; font-weight: bold"
        >金额</span
      >
      <span style="width: 25%; text-align: center; font-weight: bold"
        >岗位</span
      >
      <span style="width: 10%; text-align: center; font-weight: bold"
        >同步状态</span
      >
    </div>
    <div class="pay-item-container">
      <pay-item
        v-for="(item, index) in dataSource"
        :key="index"
        :data-source="item"
      />
    </div>
  </div>
</template>

<script>
import PayItem from "../Item/PayItem";
export default {
  props: {
    dataSource: Array,
  },
  components: {
    "pay-item": PayItem,
  },
};
</script>

<style>
.pay-list-root-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;
  background-color: #fff;
  border-radius: 10px;
  margin-top: 30px;
  padding-bottom: 10px;
}
.pay-list-header-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
  min-height: 50px;
}
.pay-item-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow-y: scroll;
}
</style>