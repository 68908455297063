export function numberFormat(number) {
    number = number.toFixed(2);
    let arr = number.split('.');

    return {
        integer: (parseInt(arr[0]) + "").replace(
            /\d{1,3}(?=(\d{3})+(\.\d*)?$)/g,
            "$&,"
        ), decimal: arr[1]
    };
}

export function getRandomCode(min, max) {
    let code = Math.floor(Math.random() * (max - min + 1) + min);
    return code.toString();
}