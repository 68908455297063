<template>
  <div class="project-info-root-container">
    <my-avatar
      :size="100"
      :name="dataSource.project_name"
      :img_url="dataSource.project_img_url"
    />
    <span
      style="
        font-size: 15px;
        font-weight: 500;
        color: #000000;
        margin: 10px 0px;
      "
      >关联项目：{{ dataSource.project_name }}</span
    >
    <avatar-list
      :member-list="dataSource.client_user"
      :max-count="7"
      @show-all="showAll"
    />
    <span
      style="
        font-size: 12px;
        font-weight: 500;
        color: #747474;
        margin: 10px 0px;
      "
      >共计金额</span
    >
    <fee
      :amount="dataSource.total_amount"
      :primarySize="15"
      :secondarySize="15"
    />
    <span
      style="
        font-size: 12px;
        font-weight: 500;
        color: #747474;
        margin: 10px 0px;
      "
      >共计{{ dataSource.client_user.length }}人</span
    >

    <pay-list-modal ref="payListModal" :data-source="pay_list" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Fee from "../Fee";
import MyAvatar from "../MyAvatar";
import AvatarList from "../List/AvatarList";
import PayListModal from "../Modal/PayListModal";
export default {
  components: {
    fee: Fee,
    "my-avatar": MyAvatar,
    "avatar-list": AvatarList,
    "pay-list-modal": PayListModal,
  },
  props: {
    dataSource: Object,
    id: Number,
    type: Number,
  },
  data() {
    return {
      pay_list: [],
    };
  },
  methods: {
    ...mapActions({
      planGetPayListAction: "planGetPayList",
      milestoneGetPayListAction: "milestoneGetPayList",
    }),
    showAll() {
      if (this.type == 1) {
        this.milestoneGetPayListAction({
          employer_id: localStorage.getItem("employer_id"),
          milestone_id_arr: [this.id],
        }).then((res) => {
          this.pay_list = res;
          this.$refs.payListModal.open("#" + this.id + "#的付款列表", "");
        });
      } else {
        this.planGetPayListAction({
          employer_id: localStorage.getItem("employer_id"),
          pay_plan_id_arr: [this.id],
        }).then((res) => {
          this.pay_list = res;
          this.$refs.payListModal.open("#" + this.id + "#的付款列表", "");
        });
      }
    },
  },
  computed: {
    memberCount: function () {
      return this.dataSource.member_list.length;
    },
  },
};
</script>

<style scoped>
.project-info-root-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>