<template>
  <div class="excel-reference-root-container">
    <span style="font-size:12px;font-weight:500;color:#000000;margin-bottom:20px;">引用的Excel文件</span>
    <div class="excel-reference-main-container">
      <accessories-item
        v-for="item in dataSource"
        :key="item.file_url"
        :data-source="item"
        style="margin-bottom: 10px;width:100%;min-width:170px;"
      />
    </div>
  </div>
</template>

<script>
import AccessoriesItem from "../Item/AccessoriesItem";
export default {
  components: {
    "accessories-item": AccessoriesItem,
  },
  props: {
    dataSource: Array,
  },
};
</script>

<style scoped>
.excel-reference-root-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow-y: hidden;
}
.excel-reference-main-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow-y: scroll;
}
</style>