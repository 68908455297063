<template>
  <div
    :class="[
      'fee-root-container',
      feeType == 'out' ? 'fee-out' : '',
      feeType == 'in' ? 'fee-in' : '',
    ]"
    :style="{ fontSize: primarySize + 'px' }"
  >
    ￥{{ amountFormat.integer }}.
    <span :style="{ fontSize: secondarySize + 'px' }">{{
      amountFormat.decimal
    }}</span>
  </div>
</template>

<script>
import { numberFormat } from "../utils/numberHelper";
export default {
  props: {
    feeType: {
      type: String,
      default: "",
    },
    amount: Number,
    primarySize: Number,
    secondarySize: Number,
  },
  computed: {
    amountFormat: function () {
      return numberFormat(this.amount || 0);
    },
  },
};
</script>

<style scoped>
.fee-root-container {
  font-weight: bold;
  color: #000000;
}
.fee-out {
  color: #37a320;
}
.fee-in {
  color: #ed4014;
}
</style>